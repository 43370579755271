<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="保养项"
    width="600px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      :model="model"
      class="form"
      label-width="100px">
      <el-form-item label="保养项名称" prop="content" :rules="$rule.notNull">
        <el-input v-model="model.content" :placeholder="$l('common.enter','请输入')" type="textarea" :rows="2"></el-input>
      </el-form-item>
      <el-form-item label="基本要求" prop="required" :rules="$rule.notNull">
        <el-input v-model="model.required" :placeholder="$l('common.enter','请输入')" type="textarea" :rows="2"></el-input>
      </el-form-item>
      <el-form-item label="模块" prop="module" :rules="$rule.notNull">
        <vm-dict-select v-model="model.module" type="wbItemModule"></vm-dict-select>
      </el-form-item>
      <el-form-item label="重点检查" prop="important" :rules="$rule.notNull">
        <el-switch v-model="model.important" active-value="是" inactive-value="否"></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>


  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        model: {
          content: "",
          required: "",
          module: "",
          important: "否",
        },
        index: -1,
      };
    },
    methods: {
      open(model, index) {
        this.$assign(this.model, model);
        this.index = index === undefined ? -1 : index;
        this.dialogVisible = true;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$emit("on-save", this.model, this.index);
            this.dialogVisible = false;
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
