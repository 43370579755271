<template>
  <div v-loading="contentLoading" class="wbDetail">
    <el-page-header style="margin-bottom: 10px" @back="$router.back();">
    </el-page-header>
    <el-descriptions class="margin-top" :column="4" border style="margin-bottom: 10px">
      <el-descriptions-item label="模板名称">{{model.name}}</el-descriptions-item>
      <el-descriptions-item label="地区">{{model.provinceName + model.cityName}}</el-descriptions-item>
      <el-descriptions-item label="保养类型">{{model.typeDesc}}</el-descriptions-item>
      <el-descriptions-item label="电梯类型">{{model.elevatorTypeDesc}}</el-descriptions-item>
    </el-descriptions>
    <el-button v-if="!model.buildIn" type="primary" style="margin-bottom: 5px" @click="$refs.editPage.open()">
      新增保养项
    </el-button>
    <el-table
      ref="vmTable"
      border
      :data="model.details">
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="content" label="保养项名称" align="center"></el-table-column>
      <el-table-column prop="required" label="基本要求" align="center"></el-table-column>
      <el-table-column prop="module" label="模块" align="center" width="150"></el-table-column>
      <el-table-column prop="important" label="重点检查" align="center" width="100"></el-table-column>
      <el-table-column
        v-if="!model.buildIn"
        :label="$l('common.function','操作')"
        align="center"
        class-name="vm-table_operate"
        width="150px">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row,scope.$index)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.$index)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <edit-page ref="editPage" @on-save="onSaveRow"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./DetailEdit.vue";

  export default {
    components: {EditPage},
    data() {
      return {
        contentLoading: true,
        id: 0,
        model: {
          buildIn: true,
        },
      };
    },
    mounted() {
      this.id = this.$route.query.id;
      this.getList();
    },
    methods: {
      getList() {
        this.contentLoading = true;
        this.$http.get(`wbTemplates/${this.id}`).then(data => {
          this.contentLoading = false;
          this.model = data;
        });
      },
      onSaveRow(row, index) {
        if (index < 0) {
          this.model.details.push(row);
        } else {
          Object.assign(this.model.details[index], row);
        }
        this.save();
      },
      deleteRow(index) {
        this.$confirm("确定删除吗?", this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.model.details.splice(index, 1);
          this.save();
        });
      },
      save() {
        this.$http.put(`wbTemplates/${this.id}/updateDetails`, this.model.details).then(() => {
          this.contentLoading = false;
          this.$message.success("执行成功");
          this.getList();
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
